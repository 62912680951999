.displayToolsContainer {
    width: 100vw;
    height: auto;
    display: flex; 
    justify-content: space-around;
    flex-flow: wrap;
}
.msToolcontainer {
    background: radial-gradient(var(--msToolBg1), var(--msToolBg2));
    width: 210px;
    height: 280px;
    margin: 20px;
    border: 3px solid rgb(61, 61, 61);
    border-radius: 18px;
    box-shadow: 0 0 4px 4px var(--msToolBg1);
    text-align: center;
    transition: 0.2s;
}
.msToolcontainer:hover {
    cursor: pointer;
    box-shadow: 0 0 8px 8px rgb(143, 143, 143);
    filter: brightness(140%);
    transform: scale(1.02);
}
.msToolcontainer:active {
    cursor: pointer;
    box-shadow: 0 0 2px 2px rgb(143, 143, 143);
    filter: brightness(90%);
    transform: scale(0.97);
}
.msToolTitle {
    font-family: 'Fredoka One', cursive;
    font-weight: 300;
    color: rgb(32, 46, 84);
    font-size: 20px;
}
.msToolText {
    font-family: 'Montserrat', cursive;
    font-weight: 600;
    color: rgb(15, 23, 46);
    font-size: 14px;
}
.msToolImg {
    width: 140px;
    height: auto;
    padding: 4px;
    background: rgb(150, 150, 150);
    border-radius: 6px;
    box-shadow: 3px 3px 4px 4px rgb(60, 60, 60);
}