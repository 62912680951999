@media screen and (min-width: 920px) {
    * {
        --sheetContainerWidth: 800px;
        --fontSizeText: 14px;
    }
}
@media screen and (min-width: 600px) and (max-width: 920px) {
    * {
        --sheetContainerWidth: 80vw;
        --fontSizeText: 12px;
    }
}
@media screen and (max-width: 600px) {
    * {
        --sheetContainerWidth: 90vw;
        --fontSizeText: 11px;
    }
}
/* ******************************************************* */
/* ***************** Display save button ***************** */
/* ******************************************************* */
.responseValidation {
    position: fixed;
    right: 5vw;
    top: 280px;
    width: 38px;
    height: 38px;
    outline: none;
    border: none;
    border-radius: 50%;
    padding: 0;
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
    animation: showSavedBut 2.5s linear infinite;
    transition: 0.2s;
}
@keyframes showSavedBut {
    0% {transform: scale(1);color: lightgreen;}
    40% {transform: scale(1);background: white;}
    45% {
        transform: scale(1.5);
        background: yellow;
        color: red;
        box-shadow: 0 0 6px 6px red;
    }
    60% {transform: scale(1);background: white;}
    100% {transform: scale(1);}
}
.responseValidation > span {
    padding-top: 5px;
}
.responseValidation:hover {
    animation: none;
    background: rgb(94, 245, 94);
    cursor: pointer;
    transform: scale(1.2);
}
.responseValidation:active {
    animation: none;
    background: rgb(200, 246, 34);
    cursor: pointer;
    transform: scale(0.8);
}
/* ******************************************************* */
/* ***************** Display state sheet ***************** */
/* ******************************************************* */
.sheetClosingActionContainer {
    display: flex;
    margin-left: 50%;
}
.sheetClosingAction {
    outline: none;
    border: none;
    background: rgb(245, 205, 132);
    border-radius: 4px;
    padding: 4px 12px;
    margin: 0 10px;
    transition: 0.2s;
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
}
.sheetClosingAction:hover {
    cursor: pointer;
    letter-spacing: 0.04em;
    background: rgb(255, 191, 73);
    padding: 3px 12px;
}
.sheetClosingAction:active {
    background: rgb(255, 255, 73);
    transform: scale(0.9);
}
.dispSheetState {
    font-weight: 500;
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
}
/* ******************************************************* */
/* ******************* Display top menu ****************** */
/* ******************************************************* */
.buildSheetContainer {
    position: relative;
    padding-bottom: 30px;
    background: rgba(200, 200, 200, 0.8);
    width: 90vw;
    margin: 5px 0 15px 0;
    overflow: hidden;
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.deployBut {
    font-size: 20px;
    margin: 0 15px;
    transition: 0.2s;
}
.deployBut:hover {
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 5px grey;
    margin: 0 15px;
}
.typeSheetSelection {
    margin: 6px 0 6px 10px;
    display: flex;
    flex-flow: wrap;
}
#sheetSelection {
    margin-left: 10px;
    height: 18px;
}
#sheetContentSelection {
    height: 18px;
    margin: 2px 0;
}
#sheetSelection, #sheetContentSelection {
    outline: none;
    border: none;
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
}
.buildSheetMenu {
    padding: 15px 0 10px 0;
    width: 330px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.modManagText {
    margin-left: 4vw;
    font-size: 12px;
    transform: translateY(-12px);
}
@media screen and (max-width: 600px) {
    .modManagText {
        display: none
    }
}
.modeManagerSelector {
    padding: 0 0 0 5px;
    font-size: 32px;
    transform: translateY(12px);
}
.modeManagerSelector:hover {
    cursor: pointer;
}
/* quizTypeChoice start */
.quizTypeList {
    padding: 0 0 10px 0;
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
    font-weight: 400;
    display: flex;
    flex-direction: column;
}
.quizTypeChoice {
    color: rgb(29, 1, 132);
}
.quizTypeChoice:hover {
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: blue;
    transition: 0.2s;
}
.quizTypeChoice:active {
    cursor: pointer;
    color: rgb(249, 241, 7);
    font-weight: 400;
    letter-spacing: 0;
}
/* quizTypeChoice stop */
/* helpIndicator start */
.helpIndicator {
    font-size: 10px;
    font-weight: 400;
    height: 24px;
    width: 120px;
    overflow: hidden;
    position: absolute;
    right: 10px;
    top: 6px;
    text-align: center;
    padding: 0 25px;
}
.helpIndicator > i {
    position: relative;
    top: -8px;
    text-align: center;
}
.helpIndicator:hover {
    position: fixed;
    top: 180px;
    right: 5vw;
    cursor: pointer;
    background: lightyellow;
    width: 80vw;
    z-index: 5000;
    height: auto;
}
.helpIndicatorText1 {
    font-family: 'Oxanium', cursive;
    font-size: 14px;
}
.helpIndicatorText2 {
    font-family: 'Oxanium', cursive;
    font-size: 13px;
}
/* helpIndicator stop */
.artInput {
    border: none; 
    outline: 0;
    background: transparent;
    border-bottom: 1px solid lightgrey;
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
    letter-spacing: 0.05em;
    color: var(--articleCl);
    transition: 0.25s;
}
.artInput:focus {
    filter: brightness(220%);
    border: 1px solid lightgrey;
    background: var(--articleBg);
}
.artRadio {
    transform: scale(1.5);
}
/* ******************************************************* */
/* ******************* Display one quiz ****************** */
/* ******************************************************* */
.quizDispContainer {
    z-index: 5000;
    display: flex;
    flex-direction: column;
    height: auto;
}
.sheetLine {
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
    font-weight: 500;
    width: var(--sheetContainerWidth);
    background: var(--articleBg);
    color: var(--articleCl);
    padding: 8px 0;
    margin-bottom: 4px;
    border-bottom: 1px solid rgb(150, 150, 150);
    display: flex;
    flex-direction: column;
}
.lengthIndicator {
    font-family: 'Oxanium', cursive;
    font-size: 10px;
    font-weight: 400;
    margin: 0 10px;
    background: white;
    padding: 4px 3px 2px 3px;
    border-radius: 4px;
    max-width: 50px;
    text-align: center;
}
.checkUnitContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.checkUnit {
    text-align: left;
    width: 200px;
}
.checkUnit > label {
    margin: 5px 10px;
}
textarea {
    resize: none;
}
.oneCheckbox {
    padding: 5px 0;
}
.oneCheckbox > input {
    transform: translateY(2px) scale(1.6);
    margin: 6px 10px;
}
.oneCheckbox > label > input {
    font-family: 'Oxanium', cursive;
    font-size: var(--fontSizeText);
    outline: none;
}
.inputsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* ******************************************************* */
/* ******************************************************* */
/* ******************************************************* */
/* ******************************************************* */