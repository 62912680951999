/* button 3 style */
#buttonDemo3 {
    margin: 1.5em 1.5em 0 1.5em;
    position: relative;
    background: #969696;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.1em;
    width: 100px;
    padding: 10px 15px;
    transition: 0.5s;
}
#buttonDemo3:hover {
    background: #cfcfcf;
    color: rgb(2, 128, 2);
    box-shadow: 0 0 35px var(--clr);
}
#buttonDemo3::before {
    content: '';
    position: absolute;
    inset: 2px;
    background: rgba(136, 155, 161, 0.4);
}
#buttonDemo3 span {
    position: relative;
    z-index: 1;
}
#buttonDemo3 i {
    content: '';
    position: absolute;
    inset: 0;
    display: block;
}
#buttonDemo3 i::after {
    content:'';
    position: absolute;
    bottom: -3.5px;
    left: 15%;
    width: 0;
    height: 0;
    opacity: 0;
    border: 2px solid #27282c;
    border-radius: 5px;
    background: rgb(192, 192, 192);
    transition: 0.5s;
}
#buttonDemo3:hover i::after {
    content: '✔';
    font-size: 30px;
    left: 90%;
    opacity: 1;
    bottom: 3px;
    padding-right: 5px;
    color: rgb(56, 250, 56);
    border: 2px solid var(--clr);
    width: 50px;
    height: 50px;
    border: 2px solid rgb(56, 250, 56);
    border-radius: 50%;
}
#buttonDemo3:active {
    color: yellow;
    font-weight: 800;
}
#fakeBut {
    outline: 0;
    border: 0;
    background: transparent;
}