body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.centr {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonType1 {
  font-family: 'Oxanium', cursive;
  font-size: 14px;
  letter-spacing: 0.05em;
  padding: 3px 12px;
  margin: 4px 10px;
  border-radius: 4px;
  outline: none; 
  border: none;
  background: rgb(151, 213, 234);
  color: black;
  transition: 0.1s;
}
.buttonType1:hover {
  cursor: grab;
  transform: scaleY(1.04);
  background: lightblue;
  color: blue;
}
.buttonType1:active {
  cursor: pointer;
  transform: scaleY(0.96);
  background: rgb(3, 16, 103);
  color: rgb(220, 241, 248);
}
@media screen and (min-width:660px) {
    .teamAddMemberForm {
        display: flex;
    }
}
@media screen and (max-width:660px) {
    .teamAddMemberForm {
        display: block;
    }
}