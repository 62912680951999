.closeComment {
    float: right;
    margin: 8px;
    color: red;
}
.commentContainer {
    z-index: 4000;
    position: absolute; 
    top: 220px;
    left: 0;
    width: 100vw;
    height: 70vh;
    display: flex;
    justify-content: center;
}
.commentZone {
    width: 320px;
    height: 320px;
    background: lightgrey;
    box-shadow: 0 0 1000px 1000px rgba(128, 128, 128, 0.8);
    color: black;
    font-family: 'Archivo', sans-serif;
    letter-spacing: 0.05em;
}
.commentZone > h3 {
    margin: 10px 15px;
}
.commentZone > p {
    margin: 5px 15px;
    font-size: 15px;
    text-align: justify;
    border-bottom: 1px solid grey;
}
.commentText {
    resize:none;
    margin: 7px;
}
.commentValidate {
    float: right;
    margin: 5px 20px;
    outline: 0;
    background: lightgreen;
    font-size: 16px;
    border-radius: 6px;
    transition: 0.2s;
}
.commentValidate:hover,
.closeComment:hover {
    cursor: pointer;
    transform: scale(1.05);
}
.commentValidate:active,
.closeComment:active {
    transform: scale(0.95);
}
.commentSent {
    color: lightgreen;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 30px 0 0;
    font-size: 40px;
}