.accountH3Title {
    text-align: center;
    height: 40px;
    font-family: 'Oxanium', cursive;
    font-size: 16px;
    color: var(--noticeTextColor);
    font-weight: 900;
    margin-bottom: 10px;
}
.accountHeader {
    width: 100vw;
    text-align: justify;
    padding: 0 60px;
}
.accountHeaderText {
    font-family: 'Montserrat Alternates', sans-serif;
    padding: 0 10px;
    border-radius: 6px;
    background: var(--noticeBgColor);
    color: var(--noticeTextColor);
    font-weight: 500;
    letter-spacing: 0.05em;
}
.themeZone {
    margin: 0 15px;
}
.accountFormLine, .managerCreation {
    border: 1px solid rgb(96, 95, 95);
    border-radius: 12px;
    background: var(--accountFormBgColor);
    height: 30px;
    transition: 0.4s;
    overflow: hidden;
}
@media screen and (min-width: 785px) {
    .accountHeader {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .accountHeaderText {
        text-align: justify;
        font-size: 14px;
        line-height: 1.5em;
        margin: 0 20px 0 15px;
    }
    .themeZone {
        margin: 0 15px;
    }
    .accountFormLine, .managerCreation {
        position: relative;
        width: 100vw;
        max-width: 750px;
        margin: 5px 25px;
    }
    .accountManagementContent {
        display: flex;
        justify-content: space-around;
    }
    .managerCreationTitle {
        width: 750px;
    }
    .accountFormLeft {
        width: 300px;
        margin: 0 25px;
        text-align: right;
    }
    .accountFormRight {
        width: 300px;
        margin: 0 25px;
        text-align: left;
    }
    .accountFormUnity {
        margin: 10px;
    }
    .accountFormUnity > label {
        font-size: 14px;
        margin-right: 10px;
    }
    .accountFormUnity > input {
        font-size: 14px;
    }
    .dispAccountEmail {
        font-size: 16px;
        font-weight: 600;
    }
    .dispAvatar > img {
        max-height: 150px;
    }
}
@media screen and (max-width: 785px) {
    .accountHeader {
        display: block;
        text-align: center;
    }
    .accountHeaderText {
        font-size: 12px;
        line-height: 1.3em;
        margin: 0 10px 0 10px;
    }
    .themeZone {
        width: 150px;
        margin: 0 15px;
    }
    .accountFormLine, .managerCreation {
        position: relative;
        width: 94vw;
        margin: 0 25px 50px 25px;
    }
    .managerCreationTitle {
        width: 94vw;
    }
    .accountFormLeft {
        width: 83vw;
        margin: 0 25px;
        text-align: center;
    }
    .accountFormRight {
        width: 83vw;
        margin: 0 25px;
        text-align: left;
    }
    .accountFormUnity {
        margin: 5px 10px;
    }
    .accountFormUnity > label {
        font-size: 12px;
        margin-right: 7px;
    }
    .accountFormUnity > input {
        font-size: 12px;
    }
    .dispAccountEmail {
        font-size: 14px;
        font-weight: 600;
    }
    .dispAvatar > img {
        max-height: 110px;
    }
}
.accountFormUnity > label {
    font-family: 'Oxanium', cursive;
}
.accountFormUnity > input {
    font-family: 'Montserrat Alternates', sans-serif;
    text-align: center;
    padding: 2px 4px;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 2px solid white;
    transition: 0.2s;
}
.accountFormUnity > input:focus {
    background: lightgrey;
    border-bottom: 2px solid rgb(255, 162, 0);
    transform: translateX(8px) scale(1.1);
}
.dispAvatar {
    text-align: center;
}
.dispAvatar > img {
    border-radius: 50%;
}
.accountSaving {
    margin: 15px 15px 15px 40%;
    width: 120px;
    background: rgb(64, 214, 64);
    font-family: 'Oxanium', cursive;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: 0.1s;
}
.accountSaving:hover {
    cursor: pointer;
    transform: scale(1.04);
    background: rgb(122, 224, 122);
}
.accountSaving:active {
    transform: scale(0.96);
    background: rgb(13, 134, 13);
}
.avatarDeletion {
    text-align: center;
}
.avatarDeletion > button {
    font-size: 10px;
    margin-bottom: 10px;
    background: rgb(254, 237, 87);
    transition: 0.1s;
}
.avatarDeletion > button:hover {
    cursor: pointer;
    transform: scale(1.02);
    background: rgb(246, 235, 136);
}
.avatarDeletion > button:active {
    transform: scale(0.98);
    background: rgb(226, 206, 22);
}
.modifyPwd {
    margin: 15px 15px 15px 40%;
    width: 120px;
    background: rgb(92, 170, 174);
    font-family: 'Oxanium', cursive;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: 0.1s;
}
.modifyPwd:hover {
    cursor: pointer;
    transform: scale(1.02);
    background: rgb(127, 216, 220);
}
.modifyPwd:active {
    transform: scale(0.98);
    background: rgb(9, 129, 136);
}
.pwdInitializationContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.newPwdTitle, .newPwdTitle > h3 {
    font-family: 'Oxanium', cursive;
    max-height: 30px;
    display: table-cell;
    margin-bottom: 10px;
}
.pwdInitialization {
    z-index: 100;
    width: 320px;
    height: 320px;
    background: lightgrey;
    border-radius: 12px;
    border: 1px solid grey;
    box-shadow: 0 0 1000px 1000px rgba(97, 97, 97, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.pwdFormUnity {
    display: flex; 
    flex-direction: column;
    font-family: 'Oxanium', cursive;
    font-weight: 500;
    font-size: 14px;
}
.managerCreation {
    overflow: hidden;
}
.managerCreationTitle {
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: space-between;
}
.managerCreationTitle > h5 {
    font-family: 'Oxanium', cursive;
    margin: 0;
    padding: 10px 0 10px 0;
}
.accountExpand, .accountHelp {
    margin: 4px 15px 0 15px;
    transition: 0.2;
}
.accountHelp {
    font-family: 'Oxanium', cursive;
    transform: translateY(30px);
    font-size: 12px;
    overflow: hidden;
    text-align: justify;
    padding: 0 20px;
    width: 20px; 
    height: 20px;
    transition: 0.2;
}
.accountExpand:hover {
    transform: translateX(-5px) translateY(-5px);
    font-size: 32px;
    color: orange;
    cursor: pointer;
}
.accountExpand:active {
    transform: translateX(2px) translateY(2px);
    font-size: 20px;
    color: yellow;
    cursor: pointer;
}
.accountHelp:hover {
    position: absolute;
    transform: translateX(5px) translateY(-5px);
    font-size: 12px;
    margin: 10px;
    border-radius: 12px;
    color: black;
    cursor: pointer;
    background: lightgrey;
    width: auto; 
    height: auto;
}
.memberTable {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .memberTable {
    max-height: 130px;
    max-width: 730px;
    overflow: auto;
  }
  .memberTd {
    font-size: 12px;
    color: black;
    background: var(--accountFormBgColor);
  }
  .addMemberBut {
    outline: none;
    border: none;
    background: rgb(104, 242, 104);
    border-radius: 6px;
    padding: 4px 10px;
    margin: 10px 40px 10px  0;
    float: right;
    transition: 0.2s;
  }
  .addMemberBut:hover {
    cursor: pointer;
    letter-spacing: 0.05em;
    transform: scale(1.02);
  }
  .addMemberBut:active {
    cursor: pointer;
    color: yellow;
    letter-spacing: 0em;
    transform: scale(0.98);
  }
  #callMember, #delMember {
    outline: 0;
    border: 0;
    padding: 4px 4px 1px 4px;
    font-family: 'Oxanium', cursive;
    font-size: 12px;
    margin: 1px;
  }
  #callMember {
    background: rgb(123, 158, 245);
  }
  #delMember {
    background: rgb(246, 117, 88);
  }
  #callMember:hover, #delMember:hover {
    transform: scaleY(1.1);
    cursor: pointer;
    filter: brightness(130%);
  }
  #callMember:active, #delMember:active {
    transform: scaleY(0.95);
    filter: brightness(80%);
  }
  .importTemplate {
    margin: 5px 35px;
    display: flex;
    justify-content: center;
  }
  .importExportStock {
      background: rgb(146, 158, 123);
      margin: 10px 35px;
      display: flex;
      justify-content: center;
  }
  .importStock {
      max-width: 250px;
  }
  #downloadTemplate {
    float: right;
    font-family: 'Oxanium', cursive;
    font-size: 12px;
  }
  #animLoadingXlsx {
    transform: translateX(30px);
    transition: 0.6s;
    opacity: 0;
    animation: animLoading 1s infinite;
  }
  @keyframes animLoading {
    0%{transform: translateX(30px);opacity: 1;}
    20%{opacity: 1;}
    80%{opacity: 1;}
    100%{transform: translateX(-30px);opacity: 0;}
  }