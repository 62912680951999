.App {
  /* background: grey; */
  background-image: var(--bgImage);
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.centr {
  display: flex;
  justify-content: space-between; 
  align-items: center;
}
.closeButton {
  float: right;
  margin: 10px;
  font-size: 32px;
  color: rgb(246, 63, 63);
  transition: 0.2s;
}
.closeButton:hover {
  cursor: pointer;
  transform: scale(1.05);
  color: red;
  border-radius: 50%;
  box-shadow: 0 0 5px 5px red;
}
.closeButton:active {
  cursor: pointer;
  transform: scale(0.95);
  color: yellow;
  border-radius: 50%;
  box-shadow: 0 0 5px 5px yellow;
}
.modalContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5000;
    display: flex; 
    justify-content: center;
    align-items: center;
}
.validationButton {
  padding: 2px 8px;
  outline: none;
  border: none;
  border-radius: 4px;
  border: 1px solid grey;
  background: rgb(135, 228, 135);
  font-family: 'Oxanium', cursive;
  font-size: 14px;
  transition: 0.2s;
}
.validationButton:hover {
  cursor: pointer;
  letter-spacing: 0.05em;
  padding: 2px 4px;
  box-shadow: 0 0 3px 3px rgb(186, 186, 186);
  background: rgb(72, 238, 72);
}
.validationButton:active {
  transform: scale(0.95);
  background: rgb(224, 238, 72);
}
.deletionButton {
  padding: 2px 8px;
  outline: none;
  border: none;
  border-radius: 4px;
  border: 1px solid grey;
  background: rgb(239, 87, 64);
  font-family: 'Oxanium', cursive;
  font-size: 14px;
  transition: 0.2s;
}
.deletionButton:hover {
  cursor: pointer;
  letter-spacing: 0.05em;
  padding: 2px 4px;
  box-shadow: 0 0 3px 3px rgb(186, 186, 186);
  background: rgb(255, 46, 14);
}
.deletionButton:active {
  transform: scale(0.95);
  background: rgb(241, 106, 106);
}