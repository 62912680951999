@media screen and (min-width:854px) {
    .dim {
        --headerHeight: 20vh;
        --h1FontSize: 14px;
        --h1MarginTop: 0;
        --h1MarginBottom: 3vw;
        --h1MarginLeft: 8vw;
        --personFontSize: 80px;
        --dispNameFontSize: 16px;
        --addIconFontSize: 32px;
    }
    .headerLeftMedia {
        display: flex;
    }
}
@media screen and (min-width:512px) and (max-width: 854px){
    .dim {
        --headerHeight: 20vh;
        --h1FontSize: 10px;
        --h1MarginTop: 0;
        --h1MarginBottom: 0;
        --h1MarginLeft: 3vw;
        --personFontSize: 60px;
        --dispNameFontSize: 12px;
        --addIconFontSize: 32px;
    }
    .headerLeftMedia {
        display: inline;
    }
}
@media screen and (min-width:322px) and (max-width: 512px){
    .dim {
        --headerHeight: 20vh;
        --h1FontSize: 8px;
        --h1MarginTop: 6vw;
        --h1MarginBottom: 0;
        --h1MarginLeft: 3vw;
        --personFontSize: 60px;
        --dispNameFontSize: 12px;
        --addIconFontSize: 24px;
    }
    .headerLeftMedia {
        display: inline;
    }
    .identityDiv {
        position: absolute;
        left: 32vw;
        top: 0;
    }
}
@media screen and (min-width:0) and (max-width: 322px){
    .headerGlobalStyle {
        --headerHeight: 0;
        overflow: hidden;
    }
}
.headerGlobalStyle {
    height: 170px;
    width: 100%;
    display: flex;
    background-image: var(--bgHeaderImage);
    color: var(--headerColor);
    justify-content: space-between;
    align-items: center;
}
/* ********** CGU ********* */
.cguContainer {
    padding: 15px;
    position: absolute;
    top: 115px;
    left: 20px;
    transition: 0.2s;
}
.cguContainer:hover {
    cursor: pointer;
    transform: scale(1.05);
}
.cguIcon {
    padding: 4px;
    transform: translateY(5px);
    animation: cguClign 1.2s linear infinite;
}
.cguText1 {
    transform: translateY(-10px);
    animation: cguClign 1.2s linear infinite;
}
.cguText2 {
    transform: translateY(-10px);
    animation: cguClign 1.2s linear infinite;
}
@keyframes cguClign {
    0%{color: rgb(248, 190, 45);}
    15%{color: rgb(248, 154, 46);}
    25%{color: rgb(249, 236, 58);}
    100%{color: rgb(248, 190, 45);}
}
@media screen and (min-width:870px) {
    .cguText1{
        opacity: 1;
        font-size: 12px;
    }
    .cguText2{
        opacity: 0;
        font-size: 0;
    }
}
@media screen and (max-width:870px) {
    .cguText1{
        opacity: 0;
        font-size: 0;
    }
    .cguText2{
        opacity: 1;
        font-size: 12px;
    }
}
/* ****** Logo style ****** */
.headerLogo {
    width: 16vw;
    max-width: 240px;
    min-width: 100px;
    height: auto;
    filter: grayscale(30%);
    border-radius: 8px;
    margin: 5px 15px 15px 25px;
    color: var(--headerBtColor);
    transition: 0.2s;
}
.headerLogo:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 0 0 3px 3px grey;
}
.headerLogo:active {
    cursor: pointer;
    transform: scale(0.98);
    box-shadow: 0 0 3px 3px grey;
}
/* ***** Header style ***** */
.h1HeaderStyle {
    font-size: var(--h1FontSize);
    padding: var(--h1MarginTop) 10px 5px var(--h1MarginLeft);
    margin-bottom: var(--h1MarginBottom);
    padding-bottom: 20px;
    font-family: 'Archivo', sans-serif;
    letter-spacing: 0.1em;
}
/* ***** Identity div ***** */
.identityDiv {
    min-width: 160px;
    max-width: 15vw;
    margin-right: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.identity {
    color: var(--personColor);
    font-size: var(--personFontSize);
    max-height: 110px;
}
.identity > img {
    border-radius: 50%;
    max-width: 10vw;
    max-height: 100px;
}
.displayName { 
    font-family: 'Nova Flat', cursive;
    font-size:  var(--dispNameFontSize);
    display: inline;
    max-width: 210px;
    max-height: 25px;
    background: var(--noticeBgColor);
    color: var(--noticeTextColor);
    border-radius: 3px;
    padding: 1px 4px;
}
/* ****** Settings button ****** */
.actionHeaderZone {
    height: var(--headerHeight);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
/* ****** Settings button ****** */
.settingButtonDiv {
    float: right;
    transform: scale(1.25);
    margin: 24px 12px 0 60px;
}
.commentIcon {
    font-size: 40px;
    animation: settingRotate 4s linear infinite;
}
.commentIcon:hover {
    animation: settingRotate 1s linear infinite;
    cursor: pointer;
    color: rgb(98, 238, 98);

}
@keyframes settingRotate {
    0%{transform: rotate(0);}
    45%{transform: rotate(0);}
    50%{transform: rotate(40deg);}
    55%{transform: rotate(-20deg);}
    60%{transform: rotate(0);}
    100%{transform: rotate(0);}
}
/* ****** Add button ****** */
#errorMaxTask {
    max-height: 30px;
    color: red;
    border: 1px solid red;
    border-radius: 50%;
    padding: 10px 8px 5px 8px;
}
 #taskIndice {
    padding: 3px 8px;
    border: 1px solid orange;
    border-radius: 50%;
    position: relative;
    top: -8px;
    left: -6px;
 }
.addButtonDiv { 
    margin: 2vw 1vw;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
}
.addButtonDiv:hover,
.addButton:hover,
.addIcon:hover {
    cursor: grab;
    transform: scale(1.05);
    filter: brightness(120%);
}
.addButtonDiv:active,
.addButton:active,
.addIcon:active {
    cursor: pointer;
    transform: scale(0.95);
    filter: brightness(75%);
}
.addButton {
    border: 0;
    outline: 0;
    background: transparent;
    color: var(--headerBtColor);
    font-size: var(--dispNameFontSize);
    transition: 0.2s;
}
.addIcon {
    font-size: var(--addIconFontSize);
    color: var(--headerBtColor)
}
/* ******** Authentication zone ****** */
.loginButonZone {
    margin-left: 70px;
    height: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
}
.unidentity {
    position: absolute;
    font-size: 120px;
    animation: unidentifiedMark 1.2s infinite linear;
}
@keyframes unidentifiedMark {
    0%{
        transform: scale(1);
        color: rgba(255, 0, 0, 0.5);
    }
    10%{
        transform: scale(1);
        color: rgba(255, 0, 0, 0.2);
    }
    90%{
        transform: scale(0.5);
        color: rgba(255, 0, 0, 0.1);
    }
    100%{
        transform: scale(1);
        color: rgba(255, 0, 0, 0.4);
    }
}
.signinBut, .signupBut,.signoutBut, .resetPass {
    font-family: 'Nova Flat', cursive;
    border-radius: 4px;
    z-index: 1000;
    transition: 0.2s;
}
.resetPass {
    font-size: 11px;
    background: rgba(214, 180, 28, 0.4);
}
.signupBut {
    background: rgba(94, 115, 234, 0.4);
    width: 85%;
}
.signinBut {
    transform: scale(1.1);
    background: rgba(113, 243, 124, 0.6);
    width: 85%;
}
.resetPass:hover {
    cursor: pointer;
    transform: scale(1.1);
    filter: brightness(1.5);
}
.resetPass:active {
    cursor: pointer;
    transform: scale(0.95);
    filter: brightness(0.9);
}
.signupBut:hover {
    cursor: pointer;
    transform: scale(1.1);
    filter: brightness(1.5);
    background: rgba(151, 165, 241, 0.4);
}
.signupBut:active {
    cursor: pointer;
    transform: scale(0.95);
    filter: brightness(0.9);
}
.signinBut:hover {
    cursor: pointer;
    transform: scale(1.2);
    filter: brightness(1.2);
    background: rgba(28, 214, 44, 0.8);
    border-radius: 19% 10% 20% 10% / 93% 0% 100% 1% ;
}
.signinBut:active {
    cursor: pointer;
    transform: scale(1.15);
    filter: brightness(0.9);
}
.signoutBut {
    background: rgba(209, 54, 54, 0.5);
}
@media screen and (min-width:925px) {
    .loginButonZone {
        width: 14vw;
        min-width: 120px;
        max-width: 140px;
    }
    .unidentity {
        left: 10px;
    }
    .signinBut, .signoutBut {
        font-size: 14px;
    }
}
@media screen and (min-width:530px)  and (max-width:925px) {
    .loginButonZone {
        width: 12vw;
        min-width: 110px;
        max-width: 120px;
    }
    .unidentity {
        left: -5px;
    }
    .signinBut, .signoutBut {
        font-size: 12px;
    }
    .identityDiv {
        margin-bottom: 35px;
    }
}
@media screen and (max-width:530px) {
    .loginButonZone {
        width: 12vw;
        min-width: 110px;
        max-width: 120px;
        position: absolute;
        left: 32vw;
        padding-bottom: 45px;
        
    }
    .unidentity {
        left: -5px;
    }
    .signinBut, .signoutBut {
        font-size: 12px;
    }
    .identityDiv {
        margin-bottom: 35px;
    }
}
/* ******** initPassword ******* */

.initP {
    width: 300px;
    height: 300px;
    background: rgb(238, 236, 236);
    border-radius: 12px;
    position: absolute;
    top: 180px;
    right: 5vw;
    box-shadow: 0 0 1000px 1000px rgba(125, 125, 125, 0.6);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.initTitle {
    font-size: 18px;
    font-family: 'Archivo', sans-serif;
    letter-spacing: 0.1em;
    text-align: justify;
    color: black;
}
.initInput {
    font-size: 20px;
    font-family: 'Archivo', sans-serif;
    letter-spacing: 0.1em;
    text-align: justify;
    color: black;
    padding: 3px;
}
.initText {
    font-size: 14px;
    font-family: 'Archivo', sans-serif;
    letter-spacing: 0.1em;
    text-align: justify;
    color: black;
}
.initBut {
    background: rgb(147, 247, 138);
    border-radius: 6px;
    padding: 8px;
    font-size: 18px;
    font-family: 'Archivo', sans-serif;
    letter-spacing: 0.1em;
    text-align: justify;
    color: black;
    transition: 0.2s;
}
.initBut:hover {
    background: rgb(147, 247, 138);
    padding: 10px;
}
.initBut:active {
    background: rgb(147, 247, 138);
    padding: 6px;
}