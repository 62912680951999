.taskStyle {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: 0.2s;
}
.theTask {
    background: lightgrey;
    border-radius: 8px;
}
.theTask:hover {
    box-shadow: 0 0 5px 5px rgba(10, 0, 0, 0.4);
    filter: brightness(1.05);
}
@media screen and (max-width:322px) {
    .taskStyle {
        width: 0;
        height: 0;
        overflow: hidden;
    }
}
@media screen and (min-width:322px) and (max-width:722px) {
    .taskStyle {
        width: 88vw;
    }
}
@media screen and (min-width:722px) and (max-width:1200px) {
    .taskStyle {
        width: 680px;
    }
}
@media screen and (min-width:1200px) {
    .taskStyle {
        width: 900px;
    }
}
/* *** Modal deletion *** */
.modalDeletionStyle {
    width: 90%;
    height: 200px;
    background: radial-gradient(rgb(221, 221, 221), rgb(148, 148, 148));
    box-shadow: 0 0 1000px 1000px rgba(128, 128, 128, 0.7);
    border: 1px solid black;
    border-radius: 12px;
    position: relative;
    top: 130px;
    left: 5%;
    text-align: center;
    z-index: 2000;
    transition: 0.2s;
}
.modalDeletionStyle:hover {
    cursor: default;
    background: radial-gradient(rgb(241, 240, 240), rgb(199, 199, 199));
}
.headModal {
    font-size: 16px;
    font-family: 'Oxanium', cursive;
}
.modalCentr {
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalCentr > button {
    font-size: 16px;
    font-family: 'Oxanium', cursive;
    margin: 0 15px;
    font-weight: 500;
    border-radius: 3px;
    outline: 0;
    transition: 0.2s;
}
.modalCentr > button:hover {
    cursor: pointer;
    transform: scale(1.05);
    filter: brightness(1.2);
}
.modalCentr > button:active {
    cursor: pointer;
    transform: scale(0.95);cursor: grab;
    filter: brightness(1.1);
}
#csvDelete {
    background: rgb(228, 228, 95);
}
#cancelDelete {
    background: rgb(228, 144, 95);
}
#confirmDelete {
    background: rgb(126, 228, 95);
}
#expCsv {
    text-decoration: none;
}
/* **** Deploy timer **** */
.counterZone {
    position: absolute;
    top: 4px;
    left: 50px;
    display: flex;
}
.timeBut {
    z-index: 1000;
    color: rgb(0, 17, 255);
    transition: 0.2s;
}
.timeBut:hover {
    cursor: pointer;
    color: rgb(235, 225, 88);
    transform: scale(1.1);
}
.timeBut:active {
    color: rgb(63, 53, 206);
    transform: scale(0.95);
}
.turnBut {
    animation: turnChrono 1s linear infinite;
}
@keyframes turnChrono {
    0%{transform: rotate(0);color: black;}
    100%{transform: rotate(1turn);color: yellow;}
}
.counterDeployed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
    border-radius: 6px;
    width: 150px;
    height: 45px;
    background: rgb(37, 37, 37);
    position: absolute;
    top: -2px;
    left: 30px;
}
#dispTp {
    transform: translateX(0) translateY(-4px) scaleX(0.7);
    background: lightgrey;
    padding-left: 6px;
    font-weight: 600;
    border-radius: 10px;
}
.dispTime {
    display: flex;
    justify-content: center;
    color: lightgreen;
    font-size: 12px;
    font-family: 'Oxanium', cursive;
}
#hour, #min, #sec {
    font-size: 14px;
    letter-spacing: 0.2em;
    padding: 0 5px ;
}
.dispTimeBut {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    font-family: 'Oxanium', cursive;
}
.stopTimeBut, .startTimeBut {
    font-size: 12px;
    font-family: 'Oxanium', cursive;
    border-radius: 4px;
    transition: 0.2s;
}
.stopTimeBut:hover, .startTimeBut:hover {
    cursor: pointer;
    letter-spacing: 0.1em;
}
.stopTimeBut:active, .startTimeBut:active {
    cursor: pointer;
    letter-spacing: 0.02em;
    box-shadow: 0 0 2px 2px yellow;
    transform: scale(0.9);
}
.stopTimeBut {
    padding: 0 5px;
    background: rgb(245, 127, 48);
}
.startTimeBut {
    padding: 0 5px;
    background: rgb(92, 245, 92);
}
/* ***** Deploy zone **** */
.deployZone {
    position: absolute;
    left: 0;
    top: -8px;
    padding-bottom: 4px;
    background: lightgrey;
    border-radius: 4px;
    padding: 12px 2px 0 2px;
    border-bottom: 2px solid rgb(75, 74, 74);
    transition: 0.2s;
}
.deployZone:hover {
    cursor: pointer;
    filter: brightness(120%);
}
.deployZone:active {
    cursor: pointer;
    background: rgb(233, 233, 233);
}
/* ***** Button zone **** */

.dispState {
    margin-bottom: 70px;
}
.noeditState {
    float: right;
    white-space: nowrap;
}
.noeditStateText {
    margin-right: 8px;
}
@media screen and (min-width:500px) {
    .noeditStateText {
        font-size: 14px;
    }
}
@media screen and (max-width:500px) {
    .noeditStateText {
        font-size: 12px;
    }
}
.deleteBut, #stateBut, .doneAll {
    z-index: 20;
    margin: 1px 10px;
    padding-bottom: 8px;
}
.editBut, .validBut, .deleteBut, .loopBut {
    z-index: 200;
    position: absolute;
    left: -5px;
    width: 30px;
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    transition: 0.4s;
}
.editBut, .validBut {
    top: 36px;
}
.deleteBut {
    top: 72px;
}
.loopBut {
    top: 108px;
}
.editBut:hover, .deleteBut:hover, .loopBut:hover {
    cursor: pointer;
    width: 90px;
}
.butIcon {
    padding: 0 0 7px 3px;
    font-size: 22px;
    transform: translateX(-5px) translateY(5px);
    border-radius: 6px;
}
.actionBut {
    outline: 0;
    border: 0;
    background: rgb(200, 200, 200);
    border-radius: 6px;
    margin: 4px;
    transition: 0.25s;
}
.actionBut.editBut {
    color: rgb(13, 13, 187);
}
.actionBut.editBut:hover {
    color: rgb(0, 0, 197);
}
.actionBut.editBut:active {
    color: rgb(255, 187, 0);
}
.actionBut.validBut {
    color: rgb(120, 125, 120);
}
.actionBut.validBut:hover {
    color: rgb(111, 114, 110);
}
.actionBut.doneBut:active {
    color: rgb(255, 187, 0);
}
.actionBut.deleteBut {
    color: rgb(230, 70, 70);
}
.actionBut.deleteBut:hover {
    color: rgb(245, 5, 5);
}
.actionBut.deleteBut:active {
    color: rgb(165, 3, 3);
}
.actionBut:active {
    cursor: pointer;
    transform: scale(0.9);
    filter: brightness(160%);
}
/* **** Window modal form loop **** */
.modalLoopForm {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 92%;
    height: 65%;
    z-index: 6000;
    padding: 5px 10px;
    background: lightgrey;
    box-shadow: inset 0 0 8px 8px rgb(96, 100, 158), 0 0 3px 3px rgb(26, 29, 84);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-family: 'Oxanium', cursive;
}
.modalLoopForm > h3 {
    margin: 0;
    height: 15px;
}
.modalLoopForm > p > i {
    font-size: 10px;
}
.modalLoopForm > p {
    height: 1em;
    font-size: 12px;
}
@media screen and (max-width:600px) {
    .modalLoopForm {
        font-size: 10px;
    }
    .modalLoopForm > div {
        font-size: 10px;
    }
}
#stopLoop, #validLoop {
    padding: 2px 8px;
    margin: 2px 12px;
    border-radius: 6px;
    font-size: 12px;
    font-family: 'Oxanium', cursive;
}
#stopLoop {
    background: rgb(249, 222, 85);
}
#validLoop {
    background: rgb(90, 248, 90);
}
#cancelLoop {
    float: right;
    margin-left: 10vw;
    border: 0;
    outline: 0;
    background: transparent;
    color: red;
}
#stopLoop:hover, #validLoop:hover, #cancelLoop:hover {
    transition: 0.2s;
}
#stopLoop:hover, #validLoop:hover, #cancelLoop:hover {
    cursor: pointer;
    transform: scale(1.05);
}
#stopLoop:active, #validLoop:active, #cancelLoop:active {
    transform: scale(0.95);
}
/* ***** Joint files **** */
.jointFileIndicator {
    position: absolute;
    top: 40px;
    right: 14px;
    color: rgb(37, 33, 151);
}
.jointFileIndicator > .symb {
    font-size: 26px;
    transform: translateY(4px) rotate(20deg);
}
.jointFileIndicator > #nb {
    font-size: 20px;
    font-weight: 600;
    color: rgb(69, 18, 90);
    transform: rotate(20deg);
}
.jointFileIndicator > #tt {
    color: rgb(69, 18, 90);
    font-size: 14px;
    letter-spacing: 0.05em;
    font-weight: 600;
}
@media screen and (max-width:500px) {
    .jointFileIndicator {
        transform: translateX(20px) scale(0.8);
    }
}
/* ***** Task title **** */
.taskTitle {
    z-index: 1;
    position: absolute; 
    top: 50px;
    left: 18vw;
    height: 25px;
}
#h4title {
    font-family: 'Montserrat Alternates', sans-serif;
    letter-spacing: 0.06em;
    font-size: 18px;
    height: 20px;
    padding: 0;
    margin: 0;
    line-height: 1em;
    white-space: nowrap;
    overflow: hidden;
}
@media screen and (max-width: 712px) {
    .taskTitle {
        left: 11vw;
    }
    #h4title {
        font-size: 14px;
        width: 42vw;
    }
}
@media screen and (min-width: 712px) {
    .taskTitle {
        left: 12vw;
    }
    #h4title {
        font-size: 22px;
        width: 40vw;
    }
}
.h4titleDiv {
    position: absolute;
    top: 160px;
    left: 5vw;
}
.h4titleLab {
    font-size: 20px;
}
.h4titleForm {
    width: 250px;
    font-size: 20px;
}
/* ***** Task Priority **** */
.taskPriority {
    position: relative;
    top: -50px;
}
.taskPriority > p {
    font-family: 'Montserrat Alternates', sans-serif;
    letter-spacing: 0.06em;
    position: relative;
}
.taskPriority > p {
    padding: 0 2px;
    border-radius: 4px;
    display: table-cell;
}
.taskPriority.t1 > p {
    background: rgba(255, 18, 18, 0.2);
}
.taskPriority.t2 > p {
    background: rgba(255, 84, 41, 0.2);
}
.taskPriority.t3 > p {
    background: rgba(250, 136, 65, 0.2);
}
.taskPriority.t4 > p {
    background: rgba(249, 194, 112, 0.2);
}
.taskPriority.t5 > p {
    background: rgba(255, 235, 18, 0.2);
}
.taskPriority.t6 > p {
    background: rgba(246, 255, 69, 0.2);
}
.taskPriority.t7 > p {
    background: rgba(213, 250, 127, 0.2);
}
.taskPriority.t8 > p {
    background: rgba(164, 255, 18, 0.2);
}
.taskPriority.t9 > p {
    background: rgba(97, 252, 58, 0.2);
}
.taskPriority.t10 > p {
    background: rgba(76, 247, 122, 0.2);
}
@media screen and (min-width:712px) {
    .taskPriority > p {
        font-size: 14px;
        top: -15px;
        left: 30vw;
    }
}
@media screen and (max-width:712px) {
    .taskPriority > p {
        font-size: 12px;
        top: -12px;
        left: 30vw;
    }
}
.taskPriorityInput {
    position: absolute;
    top: 100px;
    left: 30vw;
    margin: 2px 0 10px 0;
}
/* ***** Task Text **** */
.taskTextArea {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 16px;
    letter-spacing: 0.05em;
    position: relative;
    top: 90px;
    left: 10px;
    text-align: justify;
}
.taskTextArea > textarea {
    margin: 50px 0;
    width: 95%;
    height: 200px;
}
.dispTextarea {
    padding: 5px;
    border-radius: 6px;
    background: rgba(218, 218, 218, 0.4);
}
@media screen and (min-width:712px) {
    .dispTextarea,
    .taskTextArea > textarea {
        font-size: 16px;
    }
}
@media screen and (max-width:712px) {
    .dispTextarea,
    .taskTextArea > textarea {
        font-size: 12px;
    }
}
#titleCharMark {
    font-size: 14px;
    padding: 4px;
    font-weight: 600;
    border-radius: 3px;
    background: rgb(85, 85, 85);
    color: yellow
}
#contentCharMark {
    font-size: 14px; 
    padding: 4px; 
    position: relative;
    top: 45px;
    font-weight: 600;
    border-radius: 3px;
    background: rgb(85, 85, 85);;
    color: yellow
}
/* ****** mode input ****** */
.stateInputZone {
    border: 1px solid black;
    border-radius: 6px;
    z-index: 1000;
    position: absolute;
}
/* ********* File show zone ********** */
.fileShowZone {
    width: 100%;
    overflow: hidden;
}
.oneFileShow {
    width: 20px;
    height: 30px;
    margin: 3px 20px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
}
.oneFileShow.png {
    background-image: url("https://manager-système.fr/images/fileLogo/pngLg.png");
}
.oneFileShow.jpg,
.oneFileShow.peg,
.oneFileShow.JPG {
    background-image: url("https://manager-système.fr/images/fileLogo/jpgLg.png");
}
.oneFileShow.pdf {
    background-image: url("https://manager-système.fr/images/fileLogo/pdfLg.png");
}
.oneFileShow.ptx {
    background-image: url("https://manager-système.fr/images/fileLogo/ptxLg.png");
}
.oneFileShow.ocx {
    background-image: url("https://manager-système.fr/images/fileLogo/ocxLg.png");
}
.oneFileShow.lsx {
    background-image: url("https://manager-système.fr/images/fileLogo/lsxLg.png");
}
.oneFileShow.csv {
    background-image: url("https://manager-système.fr/images/fileLogo/csvLg.png");
}
.oneFileShow.txt {
    background-image: url("https://manager-système.fr/images/fileLogo/txtLg.png");
}
/* .oneFileShow.png {
    background-image: url("http://localhost:3000/images/fileLogo/pngLg.png");
}
.oneFileShow.jpg,
.oneFileShow.peg,
.oneFileShow.JPG {
    background-image: url("http://localhost:3000/images/fileLogo/jpgLg.png");
}
.oneFileShow.pdf {
    background-image: url("http://localhost:3000/images/fileLogo/pdfLg.png");
}
.oneFileShow.ptx {
    background-image: url("http://localhost:3000/images/fileLogo/ptxLg.png");
}
.oneFileShow.ocx {
    background-image: url("http://localhost:3000/images/fileLogo/ocxLg.png");
}
.oneFileShow.lsx {
    background-image: url("http://localhost:3000/images/fileLogo/lsxLg.png");
}
.oneFileShow.csv {
    background-image: url("http://localhost:3000/images/fileLogo/csvLg.png");
}
.oneFileShow.txt {
    background-image: url("http://localhost:3000/images/fileLogo/txtLg.png");
} */
.cancelDeleteFile {
    margin-left: 30px;
    color: blue;
}
.cancelDeleteFile:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.deleteFileSymb {
    margin-left: 30px;
    color: rgb(179, 11, 11);
    transition: 0.2s;
}
.deleteFileSymb:hover {
    cursor: pointer;
    transform: scale(1.1);
    color: rgb(255, 1, 1);
}
.deleteFile {
    margin-left: 30px;
    color: rgb(179, 11, 11);
    transition: 0.2s;
}
.deleteFile:hover {
    cursor: pointer;
    transform: scale(1.1);
    color: rgb(255, 1, 1);
}
.deleteFile:active {
    cursor: pointer;
    transform: scale(0.95);
    color: rgb(238, 255, 1);
}
@media screen and (max-width:600px) {
    .oneFileShow {
        margin: 2px 10px;
    }
    .deleteFile, .cancelDeleteFile {
        margin-left: 6px;
        font-size: 12px;
    }
    .cancelDeleteFile {
        margin-left: 26px;
    }
}
.showFileName {
    z-index: 5000;
    line-height: 0.8em;
    white-space: nowrap;
    width: auto;
    background: rgb(189, 189, 189);
    border-radius: 4px;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 12px;
    letter-spacing: 0.05em;
    padding: 4px 8px;
    margin-left: 30px;
}
.showFileName:hover {
    cursor: pointer;
    background: white;
}
/* ******** File loading zone ******** */
.taskFileLoader {
    border: 1px solid black;
    padding-top: 8px;
    z-index: 2000;
    margin-top: 5px;
    width: 98%;
}
#taskFileLegend {
    position: absolute;
    left: 20%;
    transform: translateY(-18px);
    font-size: 12px;
    background: rgb(173, 173, 173);
    border: 1px solid black;
    padding: 0 5px;
    border-radius: 4px;
}
.inputFileInput, 
.inputFileInput > form > input {
    font-family: 'Oxanium', cursive;
    padding: 0 15px 4px 15px;
    background: rgba(211, 211, 211, 0.2);
}
.inputFileInput > form > label > sub > i {
    padding: 0 4px;
    font-size: 11px;
}
/* ** edit mode in responsive style ** */
@media screen and ( max-width:662px) {
    .stateInputZone {
        right: 27vw;
    }
    .actionBut {
        transform: translateX(15px);
    }
    .inputFileInput > form > label {
        font-size: 14px;
    }
    .inputFileInput > form > label > sub > i {
        font-size: 10px;
    }
}
@media screen and (min-width:662px) and ( max-width:840px) {
    .stateInputZone {
        right: 13vw;
    }
    .inputFileInput > form > label {
        font-size: 18px;
    }
    .inputFileInput > form > label > sub > i {
        font-size: 12px;
    }
}
@media screen and (min-width:840px) {
    .stateInputZone {
        right: 90px;
    }
    .inputFileInput > form > label {
        font-size: 20px;
    }
    .inputFileInput > form > label > sub > i {
        font-size: 14px;
    }
} 