.modalConfirmSignUp {
    position: absolute;
    top: 100px;
    z-index: 5000;
    text-align: justify;
    width: 300px;
    height: auto;
    background: white;
    background-image: linear-gradient(45deg, rgba(130,130,145, 0.2) 0%, rgba(130,130,145, 0.2) 14.286%,rgba(134,149,146, 0.2) 14.286%, rgba(134,149,146, 0.2) 28.572%,rgba(117,120,123, 0.2) 28.572%, rgba(117,120,123, 0.2) 42.858%,rgba(118,114,127, 0.2) 42.858%, rgba(118,114,127, 0.2) 57.144%,rgba(105,131,133, 0.14) 57.144%, rgba(105,131,133, 0.14) 71.43%,rgba(135,123,145, 0.12) 71.43%, rgba(135,123,145, 0.12) 85.716%,rgba(113,110,133, 0.17) 85.716%, rgba(113,110,133, 0.17) 100.002%),linear-gradient(135deg, rgba(130,130,145, 0.2) 0%, rgba(130,130,145, 0.2) 14.286%,rgba(134,149,146, 0.2) 14.286%, rgba(134,149,146, 0.2) 28.572%,rgba(117,120,123, 0.2) 28.572%, rgba(117,120,123, 0.2) 42.858%,rgba(118,114,127, 0.2) 42.858%, rgba(118,114,127, 0.2) 57.144%,rgba(105,131,133, 0.14) 57.144%, rgba(105,131,133, 0.14) 71.43%,rgba(135,123,145, 0.12) 71.43%, rgba(135,123,145, 0.12) 85.716%,rgba(113,110,133, 0.17) 85.716%, rgba(113,110,133, 0.17) 100.002%),linear-gradient(0deg, rgba(130,130,145, 0.2) 0%, rgba(130,130,145, 0.2) 14.286%,rgba(134,149,146, 0.2) 14.286%, rgba(134,149,146, 0.2) 28.572%,rgba(117,120,123, 0.2) 28.572%, rgba(117,120,123, 0.2) 42.858%,rgba(118,114,127, 0.2) 42.858%, rgba(118,114,127, 0.2) 57.144%,rgba(105,131,133, 0.14) 57.144%, rgba(105,131,133, 0.14) 71.43%,rgba(135,123,145, 0.12) 71.43%, rgba(135,123,145, 0.12) 85.716%,rgba(113,110,133, 0.17) 85.716%, rgba(113,110,133, 0.17) 100.002%),linear-gradient(90deg, rgba(143,156,168, 0.14),rgba(96,96,114, 0.14));
    border: 1px solid grey;  
    box-shadow: 0 0 1000px 1000px rgba(128, 128, 128, 0.7);
    border-radius: 12px;
}
.h2ConfirmSignUp {
    width: 100%;
    margin-top: 50px;
    text-align: center;
    font-size: 16px;
}
.p1ConfirmSignUp {
    width: 280px;
    padding: 10px;
    text-align: justify;
    font-size: 14px;
}
.p2ConfirmSignUp {
    width: 280px;
    padding: 10px;
    text-align: justify;
    font-size: 14px;
}
.p2ConfirmSignUp:nth-child(0n+2) {
    text-align: center;
    font-size: 12px;
}