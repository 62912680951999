.apiCommunicationOldMessage {
    background: rgb(114, 170, 188);
    padding: 2px 6px 2px 6px;
    margin: 0 10px;
    border-radius: 6px;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    margin: none;
    font-family: 'Oxanium', cursive;
    font-weight: 600;
    color: rgb(0, 0, 0);
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}
.apiCommunicationMessage {
    background: rgb(142, 201, 246);
    padding: 4px 8px 3px 8px;
    margin: 0 10px;
    border-radius: 6px;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    margin: none;
    font-family: 'Oxanium', cursive;
    font-weight: 600;
    color: rgb(0, 0, 0);
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}
@media screen and (min-width: 900px) {
    .apiCommunicationMessage {
        font-size: 12px;
    }
}
@media screen and (max-width: 900px) {
    .apiCommunicationMessage {
        font-size: 10px;
    }
}
@media screen and (min-width: 900px) {
    .apiCommunicationOldMessage {
        font-size: 10px;
    }
}
@media screen and (max-width: 900px) {
    .apiCommunicationOldMessage {
        font-size: 8px;
    }
}
.loadAnimation {
    background: rgb(18, 23, 161);
    color: rgb(255, 85, 85);
    animation: loadAnimate 1.6s infinite linear;
}
@keyframes loadAnimate {
    0% {opacity: 0;transform: translateX(25px);}
    33% {opacity: 1;transform: translateX(-25px);}
    66% {opacity: 1;transform: translateX(-30px);}
    100% {opacity: 0;transform: translateX(-50px);}
}
.importExportStock {
    margin-top: 10px;
    height: auto;
    width: auto;
    border-radius: 6px;
    background: lightgrey;
    padding: 4px 16px 4px 16px;
    font-family: 'Oxanium', cursive;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}
.importExportStock > div > label {
    margin-right: 15px;
}
.importStock, .exportStock {
    transition: 0.2s;
}
.importStock:hover, .exportStock:hover {
    transform: scale(1.02);
    cursor: pointer;
    color: rgb(116, 3, 182);
}
.importStock:active, .exportStock:active {
    color: rgb(236, 245, 0);
}
@media screen and (min-width: 825px) {
    * {
        --pageSymbIndicator: 20px;
        --pageNumber: 20px;
        --perPageNumber: 16px;
        --commandSymb: 24px;
        --addModalInput: 16px;
        --initBut: 14px;
        --cellDisplay: 16px;
        --filterSize: 12px;
    }
    .stockContainer {
        display: flex;
        justify-content: center;
    }
    .quantAlert {
        font-size: 14px;
    }
    .commandText {
        font-size: 12px;
    }
}
@media screen and (max-width: 825px) {
    * {
        --pageSymbIndicator: 16px;
        --pageNumber: 12px;
        --perPageNumber: 10px;
        --commandSymb: 20px;
        --addModalInput: 12px;
        --initBut: 10px;
        --cellDisplay: 12px;
        --filterSize: 8px;
    }
    .stockContainer {
        display: flex;
        justify-content: left;
    }
    .quantAlert {
        font-size: 11px;
    }
    .commandText {
        font-size: 10px;
    }
}
.stockContainer {
    width: 100vw;
    overflow: auto;
}
.countIndicator {
    font-size: var(--cellDiaplay);
}
.addArticle {
    background-color: #6e79cd;
    color: #2c0c53;
    transition: 0.2s;
    font-size: var(--cellDiaplay);
}
.addArticle:hover {
    cursor: pointer;
    background-color: #394282;
    color: #e0cff6;
}
.addArticle:active {
    color: yellow;
}
.perPageStyle {
    background-color: #8e8e8e;
    color: #2c0c53;
}
.paginatorStyle {
    background-color: #8e8e8e;
    color: #000000;
}
.stockTable {
    margin-top: 15px;
    min-width: 680px;
}
table,
td {
    text-align: center;
    background: lightgrey;
    border: 1px solid #333;
    padding: 4px 4px;
    font-size: var(--cellDisplay);
}
tr:hover {
    cursor: grab;
    filter: brightness(110%);
}
@media screen and (min-width: 825px) {
    .tdWidtText {
        width: 140px;
        background: rgb(161, 201, 210);
    }
    .tdWidtBut, .quantWidtBut {
        width: 30px;
        background: rgb(161, 201, 210);
    }
    .tdWidtComDev {
        width: 120px;
        background: rgb(161, 201, 210);
    }
}
@media screen and (max-width: 825px) {
    .tdWidtText {
        width: 110px;
        background: rgb(161, 201, 210);
    }
    .tdWidtBut {
        width: 30px;
        background: rgb(161, 201, 210);
    }
    .quantWidtBut {
        width: 20px;
        background: rgb(161, 201, 210);
    }
    .tdWidtComDev {
        width: 80px;
        background: rgb(161, 201, 210);
    }
}
thead,
tfoot {
    background-color: #333;
    color: #c0c0c0;
}
.refreshZone {
    font-size: 10px;
    min-width: 80px;
}
.refreshIndicator {
    height: 10px;
    display: flex;
    flex-direction: column;
}
.refreshIndicator {
    height: 10px;
    position: relative;
    top: -14px;
}
.rIon {
    color: lightgreen;
}
.rIoff {
    color: red;
}
.hourRef {
    position: relative;
    top: -6px;
}
/* ****** filters ****** */
.filterStyle {
    background: rgb(217, 194, 144);
}
.filterSymb {
    position: relative;
    top: 2px;
    right: 8px;
    transition: 0.2s;
}
.filterSymb:hover {
    cursor: pointer;
    transform: scale(1.1);
    color: rgb(43, 138, 30);
}
.filterSymb:active {
    transform: scale(0.8);
}
.refFilter, .desFilter, .catFilter, .proFilter {
    background: rgb(140, 175, 210);
    max-width: 100px;
    position: relative;
    font-size: var(--filterSize);
    top: -5px;
    left: 6px;
    outline: none; 
    border: none;
}
.refFilter:focus, .desFilter:focus, .catFilter:focus, .proFilter:focus {
    background: rgb(197, 234, 183);
}
.categoriesShow {
    border: 1px solid grey;
    margin: none;
    list-style: none;
    background: rgb(185, 204, 214);
    width: 70%;
    height: 50px;
    overflow-x: hidden;
    overflow-y: auto;
}
.delCatBut {
    margin: 0 5px;
    padding: 0 4px;
    font-size: 12px;
    color: red;
    border-radius: 50%;
    border: 1px solid red;
    transition: 0.2s;
}
.delCatBut:hover {
    cursor: pointer;
    background: red;
    color: yellow;
}
.delCatBut:active {
    cursor: pointer;
    background: blue;
    color: red;
}
.textFilterContainer {
    background: rgb(236, 236, 146);
    padding: 1px 0 0 0;
    border-radius: 4px;
    border: 1px solid blue;
    min-width: 40px;
    margin-bottom: 2px;
}
.refTextFilter, .desTextFilter, 
.catTextFilter, .proTextFilter {
    font-family: 'Oxanium', cursive;
    font-size: 12px;
    padding: 4px 0 0 0;
    background: rgb(181, 214, 148);
    height: 10px;
    margin: 0 5px 3px 5px;
}
.refTextFetch {
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid blue;
    background: rgb(181, 214, 148);
    padding: 2px;
}
.refTextFetch:hover {
    cursor: pointer;
}
.topColumn {
    font-weight: 700;
}
.initBut {
    font-size: var(--initBut);
    padding: 2px 5px;
}
.initBut:hover {
    padding: 2px 5px;
    transform: scale(1.05);
}
.initBut:active {
    padding: 2px 5px;
    transform: scale(0.95);
}
.quantAlert {
    margin: 6px 4px 0 4px;
    font-family: 'Oxanium', cursive;
    font-weight: 600;
    color: red;
}
.quantAlert:hover {
    cursor: pointer;
    color: blue;
}
.quantAlert:active {
    color: magenta;
}
/* ***** add modale **** */
.addModaleWindowContainer {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addModaleWindow {
    position: absolute;
    box-shadow: 0 0 1000px 1000px rgba(128, 128, 128,0.8);
    min-height: 400px;
    height: auto;
    background: lightgrey;
    border: 2px solid #6e79cd;
    border-radius: 12px;;
}
@media screen and (max-width: 520px) {
    .addModaleWindow {
        min-width: 320px;
        width: 30vw;
    }
}
@media screen and (min-width: 520px) {
    .addModaleWindow {
        min-width: 500px;
        width: 30vw;
    }
}
.addModaleStockH3 {
    margin: 0 20px;
    height: 40px;
    border-bottom: 1px solid grey;
}
.addModaleinput {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid grey;
}
.addModaleinput > label {
    font-size: var(--addModalInput);
    padding: 4px 8px;
}
.addModaleinput > input {
    margin-right: 4px;
    font-size: var(--addModalInput);
    background: lightgrey;
}
.addModaleinput > input:focus {
    background: white;
}
.lenIndicator {
    background: rgb(71, 71, 71);
    color: rgb(245, 245, 159);
    margin-right: 4px;
    padding: 2px 4px;
    font-size: 10px;
}
.addArticleValidationBut {
    border: 1px solid black;
    border-radius: 6px;
    margin: 10px;
    padding: 4px 12px;
    background: lightgreen;
    transition: 0.2s;
}
.subArticleValidationBut {
    border: 1px solid black;
    border-radius: 6px;
    margin: 10px;
    padding: 4px 12px;
    background: rgb(238, 174, 144);
    transition: 0.2s;
}
.addArticleValidationBut:hover,
.subArticleValidationBut:hover {
    cursor: pointer;
    transform: scale(1.04);
}
.addArticleValidationBut:active,
.subArticleValidationBut:active  {
    transform: scale(0.97);
}
.pageIndicatorLeft,
.pageIndicatorRight {
    font-size: var(--pageSymbIndicator);
    margin: 8px 10px 0 10px;
    transition: 0.2s;
}
.pageIndicatorLeft:hover,
.pageIndicatorRight:hover {
    cursor: pointer;
    transform: scale(1.04);
    color: rgb(227, 171, 41);
}
.pageNumber {
    color: blue;
    font-size: var(--pageNumber);
    position: relative;
    top: -4px;
}
.stockButEditCommandForm,
.stockButGetCommand {
    color: blue;
    margin: 0 4px;
    font-size: var(--commandSymb);
    transition: 0.2s;
}
.stockButEditCommandForm:hover,
.stockButGetCommand:hover {
    cursor: pointer;
    transform: scale(1.04);
    color: rgb(227, 171, 41);
}
.editMarker {
    padding-bottom: 6px;
    transition: 0.2s;
}
.editMarker > span, 
.editProvider > span {
    position: relative;
    top: 6px;
    left: 10px;
    color: blue;
    transition: 0.2s;
}
.editMarker > span:hover,
.editProvider > span {
    transform: scale(1.05);
    color: darkblue;
}
.editMarker:hover {
    cursor: pointer;
    background: lightblue;
}
.editProvider:hover {
    cursor: pointer;
    background: rgb(196, 230, 173);
}
/* *** custom quant input number *** */
.quantContainer {
    background: lightyellow;
    display: flex;
    justify-content: center;
    align-items: center;
}
.quantContainer > input[type="number"],
.perPageContainer > input[type="number"] {
    border: none;
    background: transparent;
    text-align: center;
    -moz-appearance: text-field;
}
.quantContainer > input::-webkit-outer-spin-button,
.quantContainer > input::-webkit-inner-spin-button,
.perPageContainer > input::-webkit-inner-spin-button,
.perPageContainer > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.perPageContainer {
    background: grey;
}
.perPageContainer  span {
    color: blue;
    font-size: var(--perPageNumber);
    padding: 0 5px;
}
#quantInput {
    background: transparent;
    border: none;
    outline: none;
    text-align: center;
    font-size: var(--pageNumber);
    width: 50px;
}
#perPageMin, #perPageAdd,
#quantDesc, #quantAsc {
    background: transparent;
    width: 24px;
    height: 24px;
    font-size: var(--pageSymbIndicator);
    border: none;
    outline: 0;
}
#perPageMin:hover, #perPageAdd:hover,
#quantDesc:hover, #quantAsc:hover {
    cursor: pointer;
    background: rgb(227, 171, 41);
    border-radius: 50%;
}
.commandAnimation {
    position: relative;
    top: 5px;
    height: 20px;
}
.commandAction {
    height: 25px;
}
.commandAction {
    position: relative;
    top: 0;
    color: rgb(13, 13, 95);
    transition: 0.2s;
}
.commandAuthorImg {
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 15px;
    height: 50px;
    width: 50px;
}
.orderIndication {
    position: relative;
    top: -5px;
    font-size: 12px;
    font-family: 'Oxanium', cursive;
    animation: animCommandData 5s linear infinite;
}
.commandAnimationIcon {
    font-size: 20px;
    color: rgb(0, 173, 6);
    animation: animCommandVan 5s linear infinite;
    transition: 0.2s;
}
.commandAnimationIcon:hover,
.orderIndication:hover,
.commandAction:hover {
    padding-bottom: 5px;
    color: blue;
    cursor: pointer;
    opacity: 1;
}
.commandAnimationIcon:active,
.commandAction:active {
    color: rgb(152, 152, 3);
    opacity: 1;
}
@keyframes animCommandVan {
    0% {transform: translateY(-22px) translateX(-50px); opacity: 0.4;}
    30% {transform: translateY(-22px) translateX(0); opacity: 1;}
    41% {transform: translateY(-22px) translateX(30px); opacity: 0.4;}
    50% {transform: translateY(-22px) translateX(50px); opacity: 0;}
    100% {opacity: 0;}
}
@keyframes animCommandData {
    0% {opacity: 0;}
    45% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 1;}
}
/* *********** CommandForm ************** */
.commandFormContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.commandFormHeader {
    width: 500px;
    height: 700px;
    background: lightgrey;
    border: 1px solid grey;
    box-shadow: 0 0 1000px 1000px rgba(128, 128, 128, 0.7);
    overflow: auto;
}
.closeCommandForm {
    z-index: 1000;
    float: right;
    width: 20px;
    height: 25px;
    margin: 20px 10px 0 0;
    padding: 2px 0 0 8px;
    font-weight: 700;
    color: red;
    border-radius: 50%;
    background: rgb(165, 180, 197);
    border: 1px solid red;
    transition: 0.2s;
}
.actionGlobalBut:hover {
    cursor: pointer;
    transform: scale(1.04);
}
.actionGlobalBut:active {
    transform: scale(0.96);
}
.validationCommandForm {
    z-index: 1000;
    float: right;
    margin: 45px 10px 0 0;
    padding: 4px 6px;
    font-family: 'Oxanium', cursive;
    font-size: 14px;
    font-weight: 700;
    color: rgb(26, 129, 6);
    border-radius: 6px;
    background: rgb(165, 180, 197);
    border: 1px solid rgb(51, 255, 0);
    transition: 0.2s;
}
.backToPreparation {
    z-index: 1000;
    float: right;
    position: relative;
    left: 50px;
    padding: 2px 6px;
    border-radius: 6px;
}
#docxLink {
    font-family: 'Oxanium', cursive;
    font-size: 12px;
    font-weight: 700;
}
#docxLink > ul {
    list-style: none;
}
.commandFormTitle {
    width: 100%;
    max-height: 100px;
    max-width: 50%;
    text-align: center;
    margin: 0; 
    padding: 2px 0;
    position: relative; 
    top: -10px;
    background: rgb(165, 180, 197);
    border-bottom: 1px solid grey;
    line-height: 0.4em;
}
.commandFormTitle > h3 {
    font-size: 14px;
    font-family: 'Oxanium', cursive;
    max-height: 25px;
}
.commandFormTitle > h4 {
    font-family: 'Oxanium', cursive;
    font-size: 13px;
    max-height: 20px;
}
.commandFormTitle > h4 > span {
    color: blue;
    font-size: 16px;
    letter-spacing: 0.05em;
}
.typeOfDoc {
    position: relative;
    top: -10px;
    height: 10px;
}
.commandFormCustomer {
    margin-top: 0;
    width: 40%;
    display: flex;
    flex-direction: column;
}
.addressNotice {
    padding: 2px 5px 2px 5px;
    background: rgb(165, 180, 197);
    font-family: 'Oxanium', cursive;
    font-size: 10px;
    letter-spacing: 0.04em;
    text-align: justify;
}
.commandFormProvider {
    position: relative;
    top: -60px;
    left: 52%;
    width: 40%;
    display: flex;
    flex-direction: column;
}
.commandFormCustomer > input,
.commandFormProvider > input,
.commandFormProvider > .dispProviderName {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 12px;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid grey;
    margin: 2px 6px;
    transition: 0.2s;
}
.commandFormCustomer > input:focus,
.commandFormProvider > input:focus {
    transform: scale(1.05);
    background: rgb(250, 250, 250);
    border-bottom: 1px solid orange;
}
.commandTextHeader {
    position: relative;
    top: -50px;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 12px;
    width: 100%;
    text-align: center;
}
.commandTable {
    border: none;
    width: 470px;
    margin-left: 3%;
}
.commandTablHead > th {
    background: lightgrey;
    color: black;
    border: 1px solid grey;
}
.commandLine {
    line-height: 0.6em;
}
.commandRef {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 12px;
    width: 25%;
}
.delArt {
    margin-right: 10px;
    color: red; 
    border: 1px solid red;
    border-radius: 50%;
    padding: 4px 2px 2px 2px;
}
.delArt:hover {
    cursor: pointer;
    border: 2px solid red;
    transform: scale(1.1)
}
.delArt:hover {
    cursor: pointer;
    border: 1px solid red;
    transform: scale(0.96)
}
.commandDes {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 12px;
    width: 60%;
}
.commandQua {
    width: 15%;
}
.commandQua > input {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 12px;
    width: 40px;
}
.commandPartsNotice {
    text-align: justify;
    background: rgb(165, 180, 197);
    font-size: 14px;
    font-family: 'Oxanium', cursive;
}
.provManagWindowContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center; 
    align-items: center;
}
.provManagWindow {
    width: 500px;
    height: auto;
    background: rgb(204, 204, 204);
    box-shadow: 0 0 1000px 1000px rgba(128, 128, 128, 0.7);
    border-radius: 22px;
    border: 1px solid lightgrey;
    z-index: 100;
}
.provManagTitle {
    width: 80%;
    height: 40px;
    margin-left: 10px;
    border-bottom: 1px solid grey;
}
.provManagTitle > h4 {
    margin-left: 30%;
}
.displayProviderSelection {
    height: 30px;
    border-bottom: 1px solid grey;
}
.displayProviderData {
    height: 30px;
    border-bottom: 1px solid grey;
}
.providerDataName {
    margin-left: 3%;
    color: grey;
    font-size: 14px;
    font-family: 'Oxanium', cursive;
}
.providerData {
    float: right;
    margin: 3px 5% 0 0;
    min-width: 60%;
    font-size: 16px;
    font-family: 'Oxanium', cursive;
    outline: none;
    border: none;
    border-bottom: 1px solid lightyellow;
    background: transparent;
    transition: 0.2s;
}
.providerData:hover {
    background: lightgrey;;
}
.providerData:focus {
    background: lightyellow;
}
.providerSelectZone, .categorySelectZone {
    padding-bottom: 20px;
}
.selectExistingProvider {
    background: rgb(169, 180, 184);
}
.selectExistingCategory {
    position: absolute;
    right: 20px;
    top: 170px;
    background: rgb(169, 180, 184);
}

@media screen and (min-width: 520px) {
    .catiesInputs {
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
}
@media screen and (max-width: 520px) {
    .catiesInputs {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
}
.addCatiesBut {
    outline: none;
    border: none;
    background: rgb(90, 171, 90);
    border: 1px solid green;
    border-radius: 50%;
    transition: 0.2s;
}
.addCatiesBut:hover {
    cursor: pointer;
    background: lightgreen;
    border: 1px solid green;
    border-radius: 50%;
}
.addCatiesBut:active {
    background: rgb(221, 238, 144);
    border: 1px solid rgb(128, 0, 0);
}
.categoriesEdition {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.catiesCreation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -10px;
    left: 10px;
}
.selectExistingCategories {
    max-width: 35%;
    margin-bottom: 10px;
    background: rgb(169, 201, 212);
}
/* command info form */
.commandInfoFormContainer {
    z-index: 5000;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.commandInfoForm {
    position: relative;
    width: 330px;
    min-height: 300px;
    padding: 20px 0;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 12px;
    background: rgb(176, 176, 176);
    box-shadow: 0 0 1000px 1000px rgba(128, 128, 128, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.partCommandDetail {
    font-size: 11px;
    font-family: 'Oxanium', cursive;
    margin-right: 10px;
}
.buildCommandInput{
    width: 300px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}
.buildCommandInputInsert{
    width: 300px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
}
.buildCommandInput > label {
    font-size: 12px;
    font-family: 'Oxanium', cursive;
    padding-top: 4px;
}
.buildCommandInput > input{
    outline: none;
    border: none;
    background: lightgrey;
    font-size: 12px;
    font-family: 'Oxanium', cursive;
    transition: 0.2s;
}
.buildCommandInput > input:focus{
    border-bottom: 1px solid orange;
    background: white;
    transform: scale(1.05);
}
/* ******** pieces ou assemblage *********** */
.stockType {
    z-index: 1;
    position: absolute;
    right: 15px; top: 100px;
    width: 100vw;
    height: 40px;
    display: flex;
    justify-content: right;
    align-items: center;
}
.showMountage {
    border: 1px solid blue;
    background: rgb(198, 199, 199);
    border-radius: 4px;
    color: rgb(16, 8, 75);
    font-size: 14px;
    padding: 4px 12px;
    transition: 0.2s;
}
@media screen and (max-width: 920px) {
    .stockType {
        right: 15px; top: 136px;
    }
    .showMountage {
        border: 1px solid blue;
        background: rgb(198, 199, 199);
        border-radius: 4px;
        color: rgb(16, 8, 75);
        font-size: 8px;
        padding: 2px 5px;
        transition: 0.2s;
    }
}
.showMountage:hover {
    cursor: pointer;
    border: 1px solid blue;
    background: rgb(172, 168, 168);
    color: blue;
    letter-spacing: 0.05em;
    padding: 4px 4px;
}
.showMountage:active {
    cursor: pointer;
    border: 1px solid blue;
    background: rgb(121, 121, 121);
    color: rgb(246, 250, 175);
    letter-spacing: 0.01em;
    padding: 4px 4px;
} /* ******** Mountage part ******* */
.dispExplodedParts {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.dispAssemblyParts { 
    height: 450px;
    overflow: auto;
}
@media screen and (max-width: 500px) {
    .dispAssemblyParts { 
        transform: scale(0.8);
    }
}
.canvasMenu {
    width: 350px;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.canvasBut {
    background: lightgrey;
    padding: 6px;
    border-radius: 50%;
    transition: 0.2s;
}
.canvasBut:hover {
    cursor: pointer;
    background: rgb(255, 255, 170);
    color: brown;
    transform: scale(1.1);
}
.canvasBut:active {
    cursor: pointer;
    background: rgb(255, 255, 170);
    color: blue;
    transform: scale(0.98);
}
.showcolorChoice {
    z-index: 100;
    background: grey;
    border-radius: 6px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.colorUnit {
    width: 80px;
    padding: 2px 0;
    margin: 2px;
    text-align: center;
}
.dispAssImgLoadMessage {
    display: flex;
    justify-content: center;
    height: 30px;
}
.dispAssImgLoadMessage > p {
    height: 30px;
    padding: 2px 12px;
    font-size: 12px;
    font-family: 'Oxanium', cursive;
    background: lightgrey;
    display: table-cell;
}
.showExplodedModel { border: 1px solid red;
    width: 500px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.showExplodedModel > img {
    max-width: 500px;
}
.detailTopPage {
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.changeAssemblyImage {
    font-size: 12px;
    font-family: 'Oxanium', cursive;
    background: lightgrey;
    padding: 2px 1px;
    margin: 8px 8px 2px 8px;
}
.changeAssemblyImage > label {
    padding: 4px 2px;
}
.detailParts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 262px;
    overflow: auto;
}
@media screen and (max-width: 500px) {
    .changeAssemblyImage {
        display: none;
    }
    .detailTopPage {
        transform: scale(0.6);
    }
    .detailParts {
        transform: scale(0.8);
        overflow: hidden;
    }
}
.managMountageParts {
    padding: 30px;
}
.managMountageParts > p {
    border-radius: 6px;
    background: lightgrey;
    width: auto;
    padding: 4px 8px;
    text-align: justify;
}
.assemblyDispDetail {
    font-size: 12px;
    background: rgba(200, 199, 199, 0.8);
    border-radius: 6px;
    list-style: none;
    padding: 10px 15px 10px 10px;
}
/* ******** right part ******** */
.mountMenuChoice {
    padding: 4px 6px;
    margin: 8px 20px;
    border-radius: 4px;
    background: lightgrey;
    font-family: 'Oxanium', cursive;
    font-size: 18px;
    height: 20px;
    transition: 0.25s;
}
@media screen and (max-width: 550px) {
    .mountMenuChoice {
        padding: 2px 6px 0 6px;
        margin: 8px 10px;
        font-size: 12px;
        height: 16px;
    }
}
.mountMenuChoice:hover {
    cursor: pointer;
    transform: scale(1.05);
}
.titleMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    margin: 0 0 10px 0;
}
.showReferences {
    width: 60vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: left; 
    align-items: center;
}
.topMountageTable > td {
    background: rgb(216, 216, 56);
    font-weight: 700;
}
.editMountage {
    background: rgb(144, 185, 238);
}
.editMountage:hover {
    cursor: pointer;
    background: rgb(161, 235, 108);
}
.editMountage:active {
    background: rgb(235, 225, 108);
}
.mountTh {
    background:rgb(161, 196, 232);
    color: black;
}
.canvasAssemblCreation {
    height: 34px;
    padding: 2px 8px;
    border-radius: 6px;
    background: lightgrey;
    font-family: 'Oxanium', cursive;
    font-size: 14px;
    text-align: center;
}
.canvasAssemblCreation:hover {
    cursor: pointer;
    background: lightgreen;
    padding: 2px 7px;
    letter-spacing: 0.02em;
}
.canvasAssemblCreation:active {
    cursor: pointer;
    background: lightyellow;
    color: yellow;
}
.assemblCreateModale {
    width: 330px;
    height: 450px;
    background: lightgrey;
    box-shadow: 0 0  1000px 1000px rgba(128, 128, 128, 0.6);
    border-radius: 12px;
}
.assemblCreateForm {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.assemblFileCreationInput {
    width: 100%;
    text-align: center;
    font-family: 'Oxanium', cursive;
    font-size: 14px;
}
.assemblCreationInput {
    width: 100%;
    font-family: 'Oxanium', cursive;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.assemblCreationInput > label {
    margin-left: 10px;
}
.assemblCreationInput > input {
    margin-right: 10px;
}
.managZone {
    /* background: rgba(254, 96, 122, 0.4); */
}
.managZone:hover {
    cursor: pointer;
    filter: brightness(140%);
}
.managZone:active {
    cursor: pointer;
    filter: brightness(60%);
}
.categoriesList {
    list-style: none;
    margin: 0;
    padding: 0;
}
.categoriesList > li {
    border-bottom: 1px solid rgb(255, 255, 255);
}
/* eclaté */
.dispExplodedTitle {
    width: 100%;
    font-family: 'Oxanium', cursive;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: lightgrey;
}
.dispExplodedAssImg {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.dispExplodedAssImg > img {
    max-width: 50%;
    max-height: 50%;
    transition: 0.6s;
}
.dispExplodedAssImg > img:hover {
    position: absolute;
    transform: scale(200%);
}
@media screen and (max-width: 550px) {
    .dispExplodedAssImg > img {
        max-width: 80%;
        max-height: 80%;
        transition: 0.6s;
    }
    .dispExplodedAssImg > img:hover {
        position: absolute;
        transform: scale(120%);
    }
}
/* Assembly parts files */
.dispPartsFiles {
    font-family: 'Oxanium', cursive;
    font-size: 10px;
}
.inputPartData > p {
    padding: 0 0 0 20px;
    font-family: 'Oxanium', cursive;
    font-size: 14px;
}
.inputPartFile {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.addFileBut {
    outline: none;
    border: none;
    font-family: 'Oxanium', cursive;
    font-size: 14px;
    margin: 0 20px;
    background: lightgreen;
    border: 1px solid grey;
    border-radius: 6px;
}
.addFileBut > span {
    padding: 5px;
}
.addFileText {
    position: relative;
    left: 6px;top: -5px;
}
.fileOption1, .fileOption2, .fileOption3 {
    padding: 0 2px;
    margin: 0 3px;
    transform: translateY(5px);
    transition: 0.2s;
}
.fileOption1:hover, .fileOption2:hover, .fileOption3:hover {
    cursor: pointer;
    transform: translateY(5px) scale(1.1);
}
.fileOption1:active, .fileOption2:active, .fileOption3:active {
    color: yellow;
    transform: translateY(5px) scale(0.9);
}
.fileOption1 {
    color: magenta;
}
.fileOption2 {
    color: blue;
}
.fileOption3 {
    color: red;
}