.setTextZone {
    margin: 15px 5px 5px 5px;
    border: 1px solid lightblue;
}
#newText {
    min-width: 50vw;
    max-width: 75vw;
    font-size: 16px;
}
.textValidationButton {
    margin: 0px 5px;
    padding: 2px 5px;
}
#alertNbCounter {
    color:red;
    font-family: 'Fredoka One', cursive;
    font-size: 20px;
    font-weight: 600px;
    height: 0;
}