.noveltyModal {
    position: relative;
    width: 320px;
    height: 440px;
    border-radius: 6px;
    background: lightgrey;
    box-shadow: 0 0 1000px 1000px rgba(128, 128, 128, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.noveltyDate {
    position: absolute;
    top: 8px;
    right: 10px;
    font-family: 'Oxanium', cursive;
    font-size: 10px;
}
.noveltyModal > h4 {
    font-family: 'Oxanium', cursive;
    font-size: 16px;
}
.noveltyModal > p {
    margin: 0 15px;
    font-family: 'Oxanium', cursive;
    font-size: 12px;
}
.noveltyImgContainer,
.noveltyImgContainer > img {
    width: 280px; 
    height: 200px;
    border: 1px solid white;
}