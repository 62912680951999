.tutoZone {
    margin-top: 5vw;
    max-height: 65vh;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.tutoZone > div > h2 {
    padding-bottom: 15px;
    background: rgb(245, 245, 245);
    display: table-cell;
    padding: 0 10px; 
    border-radius: 6px;
}
.tutoZone > div > p {
    padding: 5px;
    margin-left: 30px;
    background: rgb(245, 245, 245);
    border-radius: 6px;
}
@media screen and (max-width:998px) {
    .todosVideo {
        width: 80vw;
        height: 45vw;
    }
}
@media screen and (min-width:998px) {
    .todosVideo {
        width: 46vw;
        height: 25vw;
    }
}
#tutoASavoir {
    font-family: 'Archivo', sans-serif;
    letter-spacing: 0.05em;
    max-width: 700px;
    text-align: justify;
    font-size: 16px;
    padding: 0 15px 0 0;
    line-height: 25px;
}