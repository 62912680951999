.diagSign {
    width: 270px;
    height: 436px;
    z-index: 2000;
    position: fixed;
    left: 0;
    top: 120px;
    border-radius: 8px;
    box-shadow: 0 0 1000px 1000px rgba(129, 129, 129, 0.7);
    background-image: linear-gradient(45deg, rgba(130,130,145, 0.2) 0%, rgba(130,130,145, 0.2) 14.286%,rgba(134,149,146, 0.2) 14.286%, rgba(134,149,146, 0.2) 28.572%,rgba(117,120,123, 0.2) 28.572%, rgba(117,120,123, 0.2) 42.858%,rgba(118,114,127, 0.2) 42.858%, rgba(118,114,127, 0.2) 57.144%,rgba(105,131,133, 0.14) 57.144%, rgba(105,131,133, 0.14) 71.43%,rgba(135,123,145, 0.12) 71.43%, rgba(135,123,145, 0.12) 85.716%,rgba(113,110,133, 0.17) 85.716%, rgba(113,110,133, 0.17) 100.002%),linear-gradient(135deg, rgba(130,130,145, 0.2) 0%, rgba(130,130,145, 0.2) 14.286%,rgba(134,149,146, 0.2) 14.286%, rgba(134,149,146, 0.2) 28.572%,rgba(117,120,123, 0.2) 28.572%, rgba(117,120,123, 0.2) 42.858%,rgba(118,114,127, 0.2) 42.858%, rgba(118,114,127, 0.2) 57.144%,rgba(105,131,133, 0.14) 57.144%, rgba(105,131,133, 0.14) 71.43%,rgba(135,123,145, 0.12) 71.43%, rgba(135,123,145, 0.12) 85.716%,rgba(113,110,133, 0.17) 85.716%, rgba(113,110,133, 0.17) 100.002%),linear-gradient(0deg, rgba(130,130,145, 0.2) 0%, rgba(130,130,145, 0.2) 14.286%,rgba(134,149,146, 0.2) 14.286%, rgba(134,149,146, 0.2) 28.572%,rgba(117,120,123, 0.2) 28.572%, rgba(117,120,123, 0.2) 42.858%,rgba(118,114,127, 0.2) 42.858%, rgba(118,114,127, 0.2) 57.144%,rgba(105,131,133, 0.14) 57.144%, rgba(105,131,133, 0.14) 71.43%,rgba(135,123,145, 0.12) 71.43%, rgba(135,123,145, 0.12) 85.716%,rgba(113,110,133, 0.17) 85.716%, rgba(113,110,133, 0.17) 100.002%),linear-gradient(90deg, rgba(143,156,168, 0.14),rgba(96,96,114, 0.14));
}
.modalTitle {
    font-family: 'Nova Flat', cursive;
    font-size: 18px;
    margin: 30px 0 10px 15px;
}
.modalUpTitle {
    font-family: 'Nova Flat', cursive;
    font-size: 18px;
    margin: 20px 0 20px 15px;
}
.closeButStyle {
    outline: 0;
    border: 0;
    background: transparent;
    transform: scale(1.25);
    color: red;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: 0.2s;
}
.closeButStyle:hover {
    cursor: pointer;
    transform: scale(1.35);
}
.closeButStyle:active {
    cursor: pointer;
    transform: scale(1);
}
.signinForm, .signinForm > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: 'Nova Flat', cursive;
    margin: 5px 15px 10px 10px;
}
.signinForm > div > label {
    margin: 6px 0;
}
.signinForm > div > input {
    font-family: 'Nova Flat', cursive;
    font-size: 16px;
    background: rgb(177, 177, 177);
    padding: 4px 2px;
    outline: 0;
    border-radius: 4px;
}
.signinForm > div > input:focus {
    background: lightgrey;
    transform: scale(1.1);
}
.logOutBut {
    outline: 0;
    border: 0;
    background: transparent;
    color: red;
    font-family: 'Nova Flat', cursive;
    font-size: 12px;
    margin-top: 8px;
    transition: 0.2s;
}
.logOutBut:hover {
    cursor: pointer;
    border: 1px solid red;
    letter-spacing: 0.1em;
}
.logOutBut:active {
    letter-spacing: 0em;
}
.validationMessage { 
    text-align: center;
    color:red;
}
.rememberCheck {
    width: 100%;
    font-size: 14px;
}
.additionalInput {
    position: absolute;
    right:80px;
    transform: scale(1.5);
}
#fakeBut {
    margin-top: 40px;
}