::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.loader-container {
    z-index: 5000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #161b42;
}
.loader {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 200px;
    height: 200px;
    border: 20px solid;
    border-top-color: rgb(230, 62, 62);
    border-bottom-color: rgb(90, 204, 90);
    border-right-color: rgb(73, 52, 167);
    border-left-color: rgb(240, 203, 41);
    border-radius: 50%;
    animation: spin 3s infinite linear;
}
#loadText {
    font-size: 24px;
    color: white;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes spin {
    from {
        transform: translate(-50%,-50%) rotate(0deg);
    }
    to {
        transform: translate(-50%,-50%) rotate(360deg);
    }
}