.toolbarZone {
    border-top: 1px solid var(--headerBorderColor);
    border-Bottom: 1px solid var(--headerBorderColor);
    background-image: var(--bgHeaderImage);
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: 0.4s;
}
@media screen and (max-width: 320px) {
    .toolbarZone {
        max-width: 0;
        max-height: 0;
        overflow: hidden;
    }
}
.deployFilterBut {
    color: rgb(23, 36, 224);
    background: lightgrey;
    padding: 2px;
    border-radius: 50%;
    position: absolute;
    top: 130px;
    left: 45vw;
    transition: 0.4s;
}
.deployFilterBut:hover {
    cursor: pointer;
    box-shadow: 0 0 3px 3px white;
}
/* ***** state barre **** */
.stateBar {
    width: 96vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 30px;
}
.taskStateBar {
    width: 96vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    height: 50px;
}
.iconFilter {
    float: left;
    font-size: 30px;
    color: var(--toolbChoiceColor);
}
.actionChoice { 
    position: relative;
    width: 70px;
    color: var(--toolbChoiceColor);
    font-family: 'Nova Flat', cursive;
    text-align: center;
    transition: 0.3s;
}
.actionChoice:hover {
    cursor: pointer;
    color: var(--toolbChoiceColorH);
}
.actionChoice.active {
    font-weight: 600;
    font-size: 16px;
    background: rgba(247, 236, 143, 0.616);
    color: var(--toolbChoiceColorH);
    border-radius: 50%;
    box-shadow: 0 0 2px 2px rgba(255, 204, 76, 0.6);
}
@media screen and (max-width:685px) {
    .actionChoice {
        font-size: 14px;
    }
    .actionChoice.active {
        font-size: 16px;
    }
    #initFilter > span {
        padding-left: 20px;
        font-size: 26px;
    }
}
@media screen and (min-width:685px) {
    .actionChoice {
        width: 110px;
        font-size: 16px;
    }
    .actionChoice.active {
        font-size: 22px;
    }
    #initFilter > span {
        margin-left: 20px;
        font-size: 26px;
    }
}
/* ********** Task.data filter ********** */
#indiceNbTask {
    background: rgb(66, 66, 28);
    color: rgb(231, 246, 247);
    font-size: 12px;
    font-weight: 700;
    padding: 2px 6px;
    border-radius: 50%;
    border: 1px solid rgb(231, 246, 247);
    position: absolute;
    bottom: -17px;
    left: 25%;
}
.unitFilterZone {
    height: 100%;
    text-align: center;
}
.selectTheme:hover {
    width: auto;
}
.selectTheme, .selectOption {
    outline: 0;
    padding: 2px;
    background: lightgrey;
    font-family: 'Nova Flat', cursive;
    font-size: 16px;
    margin-top: 10px;
}
.ascBut, .descBut,
.miniTimBut, .maxiTimBut {
    font-family: 'Nova Flat', cursive;
    margin: 0 5px;
    border-radius: 4px;
    transform: scale(0.8);
    background: var(--butColor);
    color: white;
    filter: grayscale(80%);
    transition: 0.25s;
}
.ascBut:hover,
.descBut:hover,
.miniTimBut:hover,
.maxiTimBut:hover {
    cursor: grab;
    transform: scale(0.9)
}
.ascBut.active,
.descBut.active,
.miniTimBut.active,
.maxiTimBut.active {
    cursor: pointer;
    transform: scale(1.1);
    filter: grayscale(0);
    font-weight: 600;
}

@media screen and (min-width:806px) {
    .selectTheme {
        width: auto;
    }
    .unitFilterZone > label, 
    .ascBut, .descBut,
    .miniTimBut, .maxiTimBut {
        font-size: 18px;
    }
}
@media screen and (max-width:806px) {
    .selectTheme {
        width: 25px;
    }
    .unitFilterZone > label, 
    .ascBut, .descBut,
    .miniTimBut, .maxiTimBut {
        font-size: 12px;
    }
}